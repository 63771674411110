import React, { useState, useContext, useEffect } from "react";

import moment from 'moment';

import { Container, Typography, Button, Grid, Avatar, CircularProgress } from "@material-ui/core";
import { Redirect, useLocation } from "react-router-dom";

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';

import * as firebase from 'firebase';

import http from "../Utils/http";
import { makeStyles } from '@material-ui/core/styles';
import { useStylesApp } from "../appStyles";
import clsx from 'clsx';

import currentContext from "../Context/current/currentContext";
import layoutContext from "../Context/layout/layoutContext";

export default function WaitingRoom() {

    const [result, setResult] = useState('');
    const [error, setError] = useState({ msg: null, icon: null });
    const [message, setMessage] = useState('');
    const [messageState, setMessageState] = useState('');
    const [redirect, setRedirect] = useState(false);

    const [showLoading, setShowLoading] = useState(true);
    const [classBackgroundImage, setClassBackgroundImage] = useState(null);

    const [eventData, setEventData] = useState(null);
    const [stateData, setStateData] = useState(null);

    const { currentRol, setCurrentRol } = useContext(currentContext);
    const { setShowHeader, setPageTitle } = useContext(layoutContext);  

    const useStylesPage = makeStyles(theme => ({
        backgroundImage: {
            // backgroundImage: 'url("/images/bkgWaitingRoomDesign.png")',
            // backgroundSize: '100vw 100vh',
            backgroundImage: 'url("/images/bkgWaitingRoom.png")',            
            backgroundSize: '100vw auto',

            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left 0px bottom 0px'
        },
        backgroundImageHide: {
            backgroundImage: 'none !important'
        },
        overlay: {
            fontFamily: "'Nunito', sans-serif",
            backgroundColor: '#FFF',

            // // backgroundImage: 'url("/images/bkgWaitingRoomDesign.png")',
            // // backgroundSize: '100vw 100vh',
            // backgroundImage: 'url("/images/bkgWaitingRoom.png")',            
            // backgroundSize: '100vw auto',

            // backgroundRepeat: 'no-repeat',
            // backgroundPosition: 'left 0px bottom 0px'
        },
        header: {
            height: '20vh',
            '& img': {
                width: '100px',
                marginTop: 30
            },
            '& h2': {
                fontSize: '22px',
                marginTop: '16px',
                fontWeight: 700,
                color: '#555'
            },
            '& p': {
                fontSize: '12px',
                fontWeight: 400,
                color: '#666',
                marginTop: '4px'
            }
        },
        content: {
            height: '80vh',
            '& h1': {
                fontSize: '22px',
                fontWeight: 700,
                marginTop: '24px',
                color: '#666'
            },
            '& h2': {
                fontSize: '16px',
                fontWeight: 700,
                marginTop: '0px',
                color: '#666'
            },
            '& p': {
                marginTop: '34px',
                fontSize: '15px',
                fontWeight: 600,
                lineHeight: 1.3,
                padding: '0px 20px',
            },
            '& .loading': {
                marginTop: '18px',
                color: '#999',
            },
        },
        // logo: {
        //     width: '100px',
        //     marginTop: 14
        // },
        waitingRoomTitle: {
            
        },
        avatar: {
            width: '25vh',
            height: '25vh',
            marginBottom: '20px'
        },
        contentConnecting: {
            color: '#666',
        },
        errorIcon: {
            fontSize: '8rem',
        },
        errorLabel: {
            fontSize: '1rem',
        },
    }));

    const location = useLocation();
    const classes = useStylesApp();
    const pageClass = useStylesPage();
    
    useEffect(() => {
        setPageTitle('Sala de Espera');
        setShowHeader(true);

        if (window.innerWidth > 600) {
            setClassBackgroundImage(1);
        } else {
            setClassBackgroundImage(0);
        }

        let auxCurrentRol = currentRol;
        if (!auxCurrentRol) {
            auxCurrentRol = localStorage.getItem('currentRol');
            setCurrentRol(auxCurrentRol);            
        }
    }, []);

    useEffect(() => {
        if (currentRol) {
            var arr = location.pathname.split('/');
            if (arr.length === 3) {
                console.log(arr[2]);
                try {
                    const arrArgs = JSON.parse(atob(arr[2]));                    
                    if (arrArgs.pid) {
                        // Muestro DEMO de Waiting Room para el profesional
                        getDataWRDemoMeducar(parseInt(arrArgs.pid));
                    } else {
                        if (arrArgs.id) {
                            getDataEventMeducar(arrArgs.id);
                        } else if (arrArgs.idx) {
                            getDataEventMeducar(arrArgs.idx);
                        } if (arrArgs.idxx) {
                            getDataEventMeducar(arrArgs.idxx);
                        }
                        //  else {
                        //     setError({ msg: 'El link es incorrecto.', icon: null });
                        // }
                    }        
                    
                } catch(ex) {
                    setError({ msg: 'El link es incorrecto.', icon: null });
                }
            }
        }
    }, [currentRol]);

    useEffect(() => {
        if (eventData) {

            let patientName = 'Carlos';
            if (!eventData.isDemo) {

                patientName = eventData.patient.name;

                const stateRef = firebase.database().ref().child('videocalls').child(eventData.professional.id).child(eventData.id).child(eventData.patient.id);
                stateRef.on('value', snapshot => {   
                    setStateData(snapshot.val());
                });
            }
            
            setMessageState(`Hola ${patientName}, ya le informamos al profesional que lo estás esperando.<br>Aguarda unos instantes.`);
            
        }
    }, [eventData]);

    useEffect(() => {
        if (stateData) {

            console.log(stateData);
            if (stateData.state < 11) {
                http.setPatientWaiting(eventData.id, eventData.patient.id);
            } else {
                if (stateData.link2) {
                    const redirect = stateData.link2.replace('?vc1=', '/vc/'); 
                    
                    setShowLoading(0);

                    var count = 5;
                    var interval = setInterval(() => {
                        if (count <= 0) {
                            clearInterval(interval);
                            setRedirect(redirect);
                        }
                        setMessageState('El canal de video ya fue creado.<br>Entrando en <b>' + count + ' segundos.</b>');
                        count--;
                    }, 1000);
                }
            }            
            
        }
    }, [stateData]);

    async function getDataEventMeducar(eventId) {
        let resp = await http.getDataEventMeducar(eventId);
        console.log(resp);
        if (resp.status == 200) {

            console.log(resp);

            if (resp.data.data) {

                console.log(resp.data.data);
                resp.data.data.hour = moment(resp.data.data.date_from).format('H:mm');
                setEventData(resp.data.data);
            
            } else {
                setError({ msg: 'La información del turno ya no está disponible.', icon: null });
            }

        } else {
            if (resp.status != 404) {
                setError({ msg: 'La información del turno es incorrecta.', icon: null });
            } else {
                setError({ msg: 'Error al conectar con el servidor.', icon: null });
            }            
        }
    }

    async function getDataWRDemoMeducar(pId) {
        let resp = await http.getDataWRDemoMeducar(pId);
        console.log(resp);
        if (resp.status == 200) {

            console.log(resp);

            if (resp.data.data) {

                console.log(resp.data.data);
                resp.data.data.isDemo = 1;
                resp.data.data.patientName = 'Carlos';

                resp.data.data.hour = moment(resp.data.data.date_from).format('H:mm');

                setEventData(resp.data.data);
            
            } else {
                setError({ msg: 'La información del turno ya no está disponible.', icon: null });
            }

        } else {
            if (resp.status != 404) {
                setError({ msg: 'La información del profesional es incorrecta.', icon: null });
            } else {
                setError({ msg: 'Error al conectar con el servidor.', icon: null });
            }            
        }
    }
  
    const handleError = (err) => {
        setError({ msg: err, icon: null });
    }

    const onClickBack = () => {
        setRedirect('/');
    }

    return (
        <>
        { (redirect) ? <Redirect to={ redirect } /> : null }

        <Container>

            {/* <Button className={ classes.btnBack } onClick={ onClickBack }>
            <ArrowBackIcon />
            </Button> */}
            
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={ clsx(classes.overlay, pageClass.overlay, (classBackgroundImage ? clsx(pageClass.backgroundImage, pageClass.backgroundImageHide) : pageClass.backgroundImage) ) } >

                <Grid item xs={12} align="center" className={ pageClass.header }>
                    <img src="/icons/LogoMeducall.png" className={ pageClass.logo } />
                    <Typography variant="h2" align="center" className={ pageClass.waitingRoomTitle }>Sala de espera</Typography>
                    {
                        (eventData)
                        ?
                            <Typography align="center" className={ pageClass.waitingRoomSubtitle }>Turno programado para las { eventData.hour }hs</Typography>
                        :
                            <Typography align="center" className={ pageClass.waitingRoomSubtitle }></Typography>
                    }                    
                </Grid>

                <Grid item xs={12} align="center" className={ pageClass.content } >

                    { 
                        (error.msg)
                        ?
                            <Grid item xs={12} align="center" className={ clsx(classes.content, pageClass.contentConnecting) } >
                                
                                {
                                    (error.icon) 
                                    ?
                                        (error.icon == 'VideocamOffIcon') 
                                        ? 
                                            <VideocamOffIcon className={ pageClass.errorIcon } /> 
                                        : 
                                            <ErrorOutlineIcon className={ pageClass.errorIcon } />                              
                                    :
                                        <ErrorOutlineIcon className={ pageClass.errorIcon } />
                                }
                                
                                <Typography align="center" className={ pageClass.errorLabel }>{ error.msg }</Typography>
                                <Button onClick={ onClickBack }>Salir</Button>
                            </Grid>
                        :
                            (message != '')
                            ?
                                <Grid item xs={12} align="center" className={ clsx(classes.content, pageClass.contentConnecting) } >
                                    <VideocamOffIcon className={ pageClass.errorIcon } />
                                    <Typography align="center" className={ pageClass.errorLabel }>{ message }</Typography>    
                                    <Button onClick={ onClickBack }>Salir</Button>
                                </Grid>
                            :
                                (eventData)
                                ?
                                    <Grid item xs={12} align="center" className={ clsx(classes.content, pageClass.contentConnecting) } >
                                        <Avatar alt={ eventData.professional.fullname } src={ eventData.professional.photo } className={ pageClass.avatar } />
                                        <Typography variant="h1" align="center">{ eventData.professional.fullname }</Typography>
                                        <Typography variant="h2" align="center">{ eventData.professional.speciality }</Typography>
                                        <Typography align="center" dangerouslySetInnerHTML={{__html: messageState}}></Typography>
                                        {
                                            (showLoading) 
                                            ?
                                                <CircularProgress className="loading" />
                                            :
                                                null
                                        }                                        
                                    </Grid>
                                :
                                    null
                        
                    }

                </Grid>

            </Grid>  

        </Container>

        </>
    );

}