import {SET_CURRENT_TOKEN,
        SET_CURRENT_ROL,
        SET_CURRENT_PAGE,
        SET_CURRENT_SESSION_ID,
        SET_CURRENT_TOKEN_PUBLISHER,
        SET_CURRENT_TOKEN_SUBSCRIBER
} from '../../Types/index';


export default (state, action) => {
    switch(action.type) {
        case SET_CURRENT_TOKEN:
            return {
                ...state,
                currentToken: action.payload
            }
        case SET_CURRENT_ROL:
            return {
                ...state,
                currentRol: action.payload
            }
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            }
        case SET_CURRENT_SESSION_ID:
            return {
                ...state,
                currentSessionId: action.payload
            }
        case SET_CURRENT_TOKEN_PUBLISHER:
            return {
                ...state,
                currentTokenPublisher: action.payload
            }
        case SET_CURRENT_TOKEN_SUBSCRIBER:
            return {
                ...state,
                currentTokenSubscriber: action.payload
            }
        default:
            return state;
    }

}