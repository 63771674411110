import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

//Pages
import Dashboard from "./Pages/Dashboard";
import ScanQr from "./Pages/ScanQr";
import ConfirmInit from "./Pages/ConfirmInit";
import VideoCall from "./Pages/VideoCall";
import VideoCallJoin from "./Pages/VideoCallJoin";
import ConfirmPatient from "./Pages/ConfirmPatient";
import WaitingRoom from "./Pages/WaitingRoom";
import PatientOut from "./Pages/PatientOut";

export default function RouterSwitch(props) {
  
  return (
    <Switch>

        <Route path="/" exact>
            { props.loggedIn ? <Dashboard /> : <Redirect to="/login" /> }                  
        </Route>

        <Route path="/out" exact>
            { props.loggedIn ? <PatientOut /> : <Redirect to="/login" /> }                  
        </Route>

        <Route path="/scan">
            { props.loggedIn ? <ScanQr /> : <Redirect to="/login" /> }                
        </Route>

        <Route path="/confirm-init">
            { props.loggedIn ? <ConfirmInit /> : <Redirect to="/login" /> }                
        </Route>

        <Route path="/video-call">
            { props.loggedIn ? <VideoCall /> : <Redirect to="/login" /> }                
        </Route>


        <Route path="/vc/:videocall_key">
            { props.loggedIn ? <VideoCallJoin /> : <Redirect to="/login" /> }                
        </Route>


        { /* PACIENTES */ }
        <Route path="/wr/:data">
            { props.loggedIn ? <WaitingRoom /> : <Redirect to="/login" /> }                
        </Route>

        <Route path="/pc/:key">
            { props.loggedIn ? <ConfirmPatient /> : <Redirect to="/login" /> }                
        </Route>
                    
    </Switch>
  );

}  