import axios from 'axios';
// import session from './session';

// const host = process.env.NODE_ENV === "production" ? "http://localhost:8080" : "https://api.meducar.com";
const host = 'https://api.meducar.com'; // 'http://localhost:8000'; // 
const hostMessaging = 'https://messaging.meducar.com'; // 'http://localhost:8000';
const realTimeDatabase = 'https://videochatapp-5f4ad.firebaseio.com/videocalls';
const msTimeout = 10000;

async function createSession() {
  let url = _getUrl(1);

  let result = {};
  result.status = -1;

  const config = {
    headers: {
      timeout: msTimeout,
        //   Authorization: 'Bearer ' + session.getToken()
    }
  }
  await axios.post(`${url}/opentok/sessions`, null, config)
  .then(response => {
    if (response) {
      result = response;
    }    
  })
  .catch(error => {
    if (error.response) {
      console.log(error.response);
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      result = error.response;
    }   
  });

  console.log(result);
  return result;
}
async function setConnectionId(videoCallKey, connectionId1, connectionId2) {
  let url = _getUrl();

  let result = {};
  result.status = -1;

  const config = {
    headers: {
      timeout: msTimeout,
        //   Authorization: 'Bearer ' + session.getToken()
    }
  }
  await axios.post(`${url}/api/video-consultations/${videoCallKey}/connection`, { connection_id_1: connectionId1, connection_id_2: connectionId2 }, config)
  .then(response => {
    if (response) {
      result = response;
    }    
  })
  .catch(error => {
    if (error.response) {
      console.log(error.response);
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      result = error.response;
    }   
  });
  
  console.log(result);
  return result;
}
async function disconnectVideoKey(videoCallKey) {
  let url = _getUrl();

  let result = {};
  result.status = -1;

  const config = {
    headers: {
      timeout: msTimeout,
        //   Authorization: 'Bearer ' + session.getToken()
    }
  }
  await axios.post(`${url}/api/video-consultations/${videoCallKey}/disconnect`, {}, config)
  .then(response => {
    if (response) {
      result = response;
    }    
  })
  .catch(error => {
    if (error.response) {
      console.log(error.response);
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      result = error.response;
    }   
  });
  
  console.log(result);
  return result;
}
async function disconnectConnectionId(sessionId, connectionId) {
  let url = _getUrl(1);

  let result = {};
  result.status = -1;

  const config = {
    headers: {
      timeout: msTimeout,
        //   Authorization: 'Bearer ' + session.getToken()
    }
  }
  await axios.delete(`${url}/opentok/sessions/${sessionId}/connections/${connectionId}`, {}, config)
  .then(response => {
    if (response) {
      result = response;
    }    
  })
  .catch(error => {
    if (error.response) {
      console.log(error.response);
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      result = error.response;
    }   
  });
  
  console.log(result);
  return result;
}
async function setTimeout(videoCallKey) {
  let url = _getUrl();

  let result = {};
  result.status = -1;

  const config = {
    headers: {
      timeout: msTimeout,
        //   Authorization: 'Bearer ' + session.getToken()
    }
  }
  await axios.post(`${url}/api/video-consultations/${videoCallKey}/timeout`, {}, config)
  .then(response => {
    if (response) {
      result = response;
    }    
  })
  .catch(error => {
    if (error.response) {
      console.log(error.response);
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      result = error.response;
    }   
  });
  
  console.log(result);
  return result;
}

async function setPatientWaiting(eventId, patientId) {
  let url = _getUrl();

  let result = {};
  result.status = -1;

  const config = {
    headers: {
      timeout: msTimeout,
        //   Authorization: 'Bearer ' + session.getToken()
    },
    crossdomain: true 
  }
  await axios.post(`${url}/api/video-consultations/events/${eventId}/patients/${patientId}/waiting`, {}, config)
  .then(response => {
    if (response) {
      result = response;
    }    
  })
  .catch(error => {
    if (error.response) {
      console.log(error.response);
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      result = error.response;
    }   
  });

  console.log(result);
  return result;
}
async function setPatientJoinVideo(eventId, patientId) {
  let url = _getUrl();

  let result = {};
  result.status = -1;

  const config = {
    headers: {
      timeout: msTimeout,
        //   Authorization: 'Bearer ' + session.getToken()
    },
    crossdomain: true 
  }
  await axios.post(`${url}/api/video-consultations/events/${eventId}/patients/${patientId}/join`, {}, config)
  .then(response => {
    if (response) {
      result = response;
    }    
  })
  .catch(error => {
    if (error.response) {
      console.log(error.response);
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      result = error.response;
    }   
  });

  console.log(result);
  return result;
}
async function setProfessionalJoinVideo(eventId, professionalId) {
  let url = _getUrl();

  let result = {};
  result.status = -1;

  const config = {
    headers: {
      timeout: msTimeout,
        //   Authorization: 'Bearer ' + session.getToken()
    },
    crossdomain: true 
  }
  await axios.post(`${url}/api/video-consultations/events/${eventId}/professionals/${professionalId}/join`, {}, config)
  .then(response => {
    if (response) {
      result = response;
    }    
  })
  .catch(error => {
    if (error.response) {
      console.log(error.response);
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      result = error.response;
    }   
  });

  console.log(result);
  return result;
}
async function setConnectionNotAvailable(eventId, professionalId, patientId) {
  let url = realTimeDatabase;

  let result = {};
  result.status = -1;

  const config = {
    headers: {
      timeout: msTimeout,
        //   Authorization: 'Bearer ' + session.getToken()
    },
    crossdomain: true 
  }
  await axios.patch(`${url}/${professionalId}/${eventId}/${patientId}.json`, { state: 95 }, config)
  .then(response => {
    if (response) {
      result = response;
    }    
  })
  .catch(error => {
    if (error.response) {
      console.log(error.response);
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      result = error.response;
    }   
  });

  console.log(result);
  return result;
}

async function getTokenPublisher(sessionId) {
  let url = _getUrl(1);

  let result = {};
  result.status = -1;

  const config = {
    headers: {
      timeout: msTimeout,
        //   Authorization: 'Bearer ' + session.getToken()
    },
    crossdomain: true 
  }
  await axios.get(`${url}/opentok/sessions/${sessionId}/token?rol=publisher`, config)
  .then(response => {
    if (response) {
      result = response;
    }    
  })
  .catch(error => {
    if (error.response) {
      console.log(error.response);
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      result = error.response;
    }   
  });

  console.log(result);
  return result;
}
async function getDataVideoCall(videoCallKey) {
  let url = _getUrl(1);

  let result = {};
  result.status = -1;

  const config = {
    headers: {
      timeout: msTimeout,
        //   Authorization: 'Bearer ' + session.getToken()
    },
    crossdomain: true 
  }
  await axios.get(`${url}/opentok/videocalls/${videoCallKey}/data`, config)
  .then(response => {
    if (response) {
      result = response;
    }    
  })
  .catch(error => {
    if (error.response) {
      console.log(error.response);
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      result = error.response;
    }   
  });

  console.log(result);
  return result;
}
async function getDataVideoCallMeducar(videoCallKey) {
  let url = _getUrl();

  let result = {};
  result.status = -1;

  const config = {
    headers: {
      timeout: msTimeout,
        //   Authorization: 'Bearer ' + session.getToken()
    },
    crossdomain: true 
  }
  await axios.get(`${url}/api/video-consultations/${videoCallKey}/data`, config)
  .then(response => {
    if (response) {
      result = response;
    }    
  })
  .catch(error => {
    if (error.response) {
      console.log(error.response);
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      result = error.response;
    }   
  });

  console.log(result);
  return result;
}
async function getDataEventMeducar(eventId) {
  let url = _getUrl();

  let result = {};
  result.status = -1;

  const config = {
    headers: {
      timeout: msTimeout,
        //   Authorization: 'Bearer ' + session.getToken()
    },
    crossdomain: true 
  }
  await axios.get(`${url}/api/video-consultations/events/${eventId}`, config)
  .then(response => {
    if (response) {
      result = response;
    }    
  })
  .catch(error => {
    if (error.response) {
      console.log(error.response);
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      result = error.response;
    }   
  });

  console.log(result);
  return result;
}
async function getDataWRDemoMeducar(pId) {
  let url = _getUrl();

  let result = {};
  result.status = -1;

  const config = {
    headers: {
      timeout: msTimeout,
        //   Authorization: 'Bearer ' + session.getToken()
    },
    crossdomain: true 
  }
  await axios.get(`${url}/api/video-consultations/professionals/${pId}/waiting-room-demo`, config)
  .then(response => {
    if (response) {
      result = response;
    }    
  })
  .catch(error => {
    if (error.response) {
      console.log(error.response);
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      result = error.response;
    }   
  });

  console.log(result);
  return result;
}

function _getUrl(is_messaging = 0) {
  let url = host;
  if (is_messaging) {
    url = hostMessaging;
  }
  return url;
}

// function _getNewToken() {
//   return new Promise((resolve, reject) => {

//     let url = _getUrl();
//     const config = {
//       headers: {
//         timeout: msTimeout,
//         Authorization: 'Bearer ' + session.getToken()
//       }
//     }

//     axios
//       .patch(`${url}/api/auth/refresh`, null, config)
//       .then(response => {
//         session.setToken(response.data.data.token);
//         resolve(response.data.data.token);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

axios.interceptors.response.use( (response) => {
    // Return a successful response back to the calling service
    return response;
  }, (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  
    // // // Logout user if token refresh didn't work or user is disabled
    // // if (error.config.url == '/api/token/refresh' || error.response.message == 'Account is disabled.') {
      
    // //   // TokenStorage.clear();
    // //   // router.push({ name: 'root' });
  
    // //   return new Promise((resolve, reject) => {
    // //     reject(error);
    // //   });
    // // }
  
    // // Try request again with new token
    // return _getNewToken()
    //   .then((token) => {
  
    //     // New request with new token
    //     const config = error.config;
    //     config.headers['Authorization'] = `Bearer ${token}`;
    //     return new Promise((resolve, reject) => {
    //       axios.request(config).then(response => {
    //         resolve(response);
    //       }).catch((error) => {
    //         reject(error);
    //       })
    //     });
  
    //   })
    //   .catch((error) => {
    //     Promise.reject(error);
    //   });
  });

const http = {
    //POST
    createSession: createSession,
    setConnectionId: setConnectionId,
    disconnectVideoKey: disconnectVideoKey,
    disconnectConnectionId: disconnectConnectionId,
    setTimeout: setTimeout,

    //PATCH
    setPatientWaiting: setPatientWaiting,
    setConnectionNotAvailable: setConnectionNotAvailable,
    setPatientJoinVideo: setPatientJoinVideo,
    setProfessionalJoinVideo: setProfessionalJoinVideo,

    //GET
    getTokenPublisher: getTokenPublisher,
    getDataVideoCall: getDataVideoCall,
    getDataVideoCallMeducar: getDataVideoCallMeducar,
    getDataEventMeducar: getDataEventMeducar,
    getDataWRDemoMeducar: getDataWRDemoMeducar,
};
export default http;