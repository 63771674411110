import React, { useState, useContext, useEffect } from "react";

import QrReader from 'react-qr-reader';
import { makeStyles, Typography, Grid, Container, Button } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import clsx from 'clsx';


import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useStylesApp } from "../appStyles";

import layoutContext from "../Context/layout/layoutContext";

export default function ScanQr(props) {

  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const [redirect, setRedirect] = useState(false);

  const { setShowHeader, setPageTitle } = useContext(layoutContext);  

  const useStylesPage = makeStyles(theme => ({
    full: {
      backgroundColor: '#000',
      color: '#BBB'
    },
    btnBack: {
      color: '#FFF'
    },
    qrContainer: {
      marginTop: 20,
      width: '100vw'
    }
  }));

  const classes = useStylesApp();
  const pageClass = useStylesPage();

  useEffect(() => {
    setPageTitle('Escanear');
    setShowHeader(true);
  }, []);
  
  const handleScan = (data) => {
    if (data) { 

      setResult(data);

      var arr = data.split('?vc1=');
      if (arr.length == 2) {
        console.log(arr[1]);
        setRedirect('/vc/' + arr[1]);
      } else {
        setError('Link incorrecto.');
      }
      // https://videochatapp-5f4ad.firebaseapp.com/vc/5e8e397a171f7      

      
    }
  }
  const handleError = (err) => {
    setError(err)
  }

  const onClickBack = () => {
    setRedirect('/');
  }

  return (
    <>
      { (redirect) ? <Redirect to={ redirect } /> : null }

      <Button className={ clsx(classes.btnBack, pageClass.btnBack) } onClick={ onClickBack }>
        <ArrowBackIcon />
      </Button>

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={ clsx(classes.full, pageClass.full) } >

        <Grid item xs={12} align="center">
          <Typography align="center" variant="p">Enfocar el código QR de acceso a la videoconsulta.</Typography>
          <QrReader
            delay={ 300 }
            onError={ handleError }
            onScan={ handleScan }
            className={ pageClass.qrContainer }
          />
          <p>{ result }</p>
          <p>{ error }</p>
        </Grid>
        
      </Grid>
      
    </>
  );

}  