import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Typography, Grid } from "@material-ui/core";

import Fab from '@material-ui/core/Fab';
import CropFreeIcon from '@material-ui/icons/CropFree';

import { makeStyles } from '@material-ui/core/styles';
import { useStylesApp } from "../appStyles";
import clsx from 'clsx';

export default function Dashboard(props) {
  const [redirect, setRedirect] = useState(null);  

  const useStylesPage = makeStyles(theme => ({
    logo: {
      width: '250px',
      marginBottom: 30
    },
    content: {
      maxWidth: '500px',
      fontSize: '1rem'
    },
    title: {
      color: '#666',
      marginBottom: 20
    },
    description: {
      color: '#999'
    }
  }));

  const classes = useStylesApp();
  const pageClass = useStylesPage();

  const onFabClick = () => {
    setRedirect('/scan');
  }
  
  return (
    <>
      { (redirect) ? <Redirect to={ redirect } /> : null }

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={ classes.full } >

        <Grid item xs={12} align="center" className={ clsx(classes.content, pageClass.content) } >
          <img src="icons/LogoMeducall.png" className={ pageClass.logo } />
          <Typography align="center" className={ pageClass.description }>Servicio de videoconsultas de Meducar.</Typography>
        </Grid>

      </Grid>
      
      <Fab aria-label='Escanear' id="fabScan" className={ classes.fab } color='primary' onClick={ onFabClick }>
        <CropFreeIcon />
      </Fab>
    
    </>
  );
}  