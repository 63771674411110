// CURRENT
export const SET_CURRENT_TOKEN = 'SET_CURRENT_TOKEN';
export const SET_CURRENT_ROL = 'SET_CURRENT_ROL';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_CURRENT_SESSION_ID = 'SET_CURRENT_SESSION_ID';
export const SET_CURRENT_TOKEN_PUBLISHER = 'SET_CURRENT_TOKEN_PUBLISHER';
export const SET_CURRENT_TOKEN_SUBSCRIBER = 'SET_CURRENT_TOKEN_SUBSCRIBER';

//LAYOUT
export const HANDLE_DRAWER = 'HANDLE_DRAWER';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_SHOW_HEADER = 'SET_SHOW_HEADER';
export const SET_SHOW_HEADER_TRANSPARENT = 'SET_SHOW_HEADER_TRANSPARENT';
