import {HANDLE_DRAWER,
        SET_PAGE_TITLE,
        SET_SHOW_HEADER,
        SET_SHOW_HEADER_TRANSPARENT
} from '../../Types/index';

export default(state, action) => {
    switch(action.type) {
        case HANDLE_DRAWER:
            return {
                ...state,
                sidebarOpen: !state.sidebarOpen
            }
        case SET_PAGE_TITLE:
            return {
                ...state,
                pageTitle: action.payload
            }
        case SET_SHOW_HEADER:
            return {
                ...state,
                showHeader: action.payload,
                showHeaderTransparent: !action.payload
            }
        case SET_SHOW_HEADER_TRANSPARENT:
            return {
                ...state,
                showHeaderTransparent: action.payload,
                showHeader: !action.payload
                
            }
        default:
            return state;
    }
}