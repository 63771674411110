import React, { useState, useContext, useEffect } from 'react';
import { Redirect, useLocation } from "react-router-dom";
import * as firebase from 'firebase';

import './App.css';

import { createMuiTheme } from '@material-ui/core/styles';

import { themeAppDefault, useStylesApp } from './appStyles';

import RouterSwitch from './RouterSwitch';
import { Container, ThemeProvider, CssBaseline } from '@material-ui/core';

//Context
import currentContext from './Context/current/currentContext';
import layoutContext from './Context/layout/layoutContext';

const firebaseConfig = {
  apiKey: "AIzaSyAyBzAEyCjBXnM9XsvE3SEt7nTu7mEGyR4",
  authDomain: "videochatapp-5f4ad.firebaseapp.com",
  databaseURL: "https://videochatapp-5f4ad.firebaseio.com",
  projectId: "videochatapp-5f4ad",
  storageBucket: "videochatapp-5f4ad.appspot.com",
  messagingSenderId: "997459553196",
  appId: "1:997459553196:web:15a7735cc4303124316809",
  measurementId: "G-TWSCE3LM94"
};
firebase.initializeApp(firebaseConfig);

function App() {
  const [redirect, setRedirect] = useState(null);
  const { setCurrentPage, setCurrentRol } = useContext(currentContext);
  const { sidebarOpen, handleDrawer } = useContext(layoutContext);
  
  const [loggedIn, setLoggedIn] = React.useState(true);

  // Themes
  const [themeDefault] = React.useState(themeAppDefault);
  const [themeCustom, setThemeCustom] = React.useState(null);

  const location = useLocation();
  const classes = useStylesApp();

  useEffect(() => {
    
    console.log('process.env.NODE_ENV', process.env.NODE_ENV);

    // Mostrar loading mientras carga data del consultorio y theme
    console.log('Mostrar loading mientras carga data del consultorio y theme');
    
    const themeCustom = {
      typography: {
        fontFamily: "'Nunito', sans-serif"
      },
      palette: {
        primary: { main: '#355768' },
        secondary: { main: '#F00' },
        type: 'light',
      },
    };
    setThemeCustom(themeCustom);
  
  }, []);
  useEffect(() => {
    
    if (location.search != '') {
      if (location.search.startsWith('?vc1=')) {
        const redirect = location.search.replace('?vc1=', '/vc/');
        
        setCurrentRol('professional');
        localStorage.setItem('currentRol', 'professional');
        
        setRedirect(redirect);
      } else if (location.search.startsWith('?vc2=')) {
        const redirect = location.search.replace('?vc2=', '/vc/');
        
        setCurrentRol('patient');
        localStorage.setItem('currentRol', 'patient');
        
        setRedirect(redirect);
      } else if (location.search.startsWith('?wr=')) {
        const redirect = location.search.replace('?wr=', '/wr/');
        
        setCurrentRol('patient');
        localStorage.setItem('currentRol', 'patient');
        
        setRedirect(redirect);
      }
    }
    
    refreshByPage(location.pathname);

  }, [location.pathname]);

  const refreshByPage = (page) => {
    console.log(page);
    switch(page) {
      default:
        break;
    }      
    setCurrentPage(page);    
  }

  return (
    <>
      { (redirect) ? <Redirect to={ redirect } /> : null }

      <Container className={ classes.container, classes.noMargin, classes.noPadding }>
        <ThemeProvider theme={ themeCustom ? createMuiTheme(themeCustom) : createMuiTheme(themeDefault) }>
          <div className={ classes.full }>
            <CssBaseline />

            <main className={ classes.full }>

              { 
                (sidebarOpen)
                  ? <div className={ classes.overlay } onClick={ handleDrawer } />
                  : null
              }

              <Container className={ classes.full }>
                <RouterSwitch loggedIn={ loggedIn } />
              </Container>
              
            </main>
          </div>

        </ThemeProvider>
      </Container>

    </>
  );
}

export default App;
