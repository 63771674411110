import React, { useState, useContext, useEffect } from "react";

import { Typography, Container, makeStyles, Grid } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import http from "../Utils/http";

import currentContext from "../Context/current/currentContext";
import { useStylesApp } from "../appStyles";

export default function ConfirmPatient(props) {
  const [redirect, setRedirect] = useState(null);
  // const { currentSessionId, setCurrentSessionId, currentTokenPublisher, setCurrentTokenPublisher } = useContext(currentContext);

  const useStylesPage = makeStyles(theme => ({
    background: {
      width: '100vw',
      height: '100vh',
      backgroundColor: '#F00'
    }
  }));
  
  const classes = useStylesApp();
  const pageClass = useStylesPage();  

  useEffect(() => {

    // if (!currentSessionId) {
    //   if (localStorage.getItem('currentSessionId')) {
    //     setCurrentSessionId(localStorage.getItem('currentSessionId'));        
    //   } else {
    //     setRedirect('/scan');
    //   }
    // }

    // getTokenPublisher();
  
  }, []);

  // const handleClick = () => {
  //   getTokenPublisher();
  // }

  return (
    <Container className={ pageClass.background }>
      
      { (redirect) ? <Redirect to={ redirect } /> : null }

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={ classes.full } >

        <Grid item xs={12} >
          <Typography align="center" variant="h5" className={ pageClass.title }>Bienvenido a Meducall</Typography>
          <Typography align="center" className={ pageClass.description }>Servicio de videoconsultas de Meducar.</Typography>
        </Grid>

      </Grid>
      
      {/* <Button onClick={ handleClick }>Iniciar</Button> */}
    </Container>
  );

}  
