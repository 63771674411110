import React, {useReducer} from 'react';

import layoutContext from './layoutContext';
import layoutReducer from './layoutReducer';

import {HANDLE_DRAWER,
        SET_PAGE_TITLE,
        SET_SHOW_HEADER,
        SET_SHOW_HEADER_TRANSPARENT
} from '../../Types/index';

const LayoutState = props => {
    const initialState = {
        showHeader: false,
        showHeaderTransparent: false,
        sidebarOpen: false,
        pageTitle: 'Home'
    }

    const [state, dispatch] = useReducer(layoutReducer, initialState);

    const handleDrawer = () => {
        dispatch({
            type: HANDLE_DRAWER
        })
    }

    const setPageTitle = title => {
        dispatch({
            type: SET_PAGE_TITLE,
            payload: title
        })
    }

    const setShowHeader = show => {
        dispatch({
            type: SET_SHOW_HEADER,
            payload: show
        })
    }
    const setShowHeaderTransparent = show => {
        dispatch({
            type: SET_SHOW_HEADER_TRANSPARENT,
            payload: show
        })
    }

    return (
        <layoutContext.Provider
            value = {{
                sidebarOpen: state.sidebarOpen,
                pageTitle: state.pageTitle,
                showHeader: state.showHeader,
                showHeaderTransparent: state.showHeaderTransparent,
                handleDrawer,
                setPageTitle,
                setShowHeader,
                setShowHeaderTransparent
            }}
        >
            {props.children}
        </layoutContext.Provider>
    )
}

export default LayoutState;