import React, { useState, useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Container, Typography } from "@material-ui/core";

import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';

import currentContext from "../Context/current/currentContext";
import { useStylesApp } from "../appStyles";

export default function VideoCall(props) {
    const [redirect, setRedirect] = useState(null);
    const [connected, setConnected] = useState(false);
    const [error, setError] = useState(false);
    const [log, setLog] = useState('');

    const { currentSessionId, setCurrentSessionId, currentTokenPublisher, setCurrentTokenPublisher } = useContext(currentContext);

    const classes = useStylesApp();

    props = {};
    props.credentials = {}
    props.credentials.apiKey = '46588282';
    
    useEffect(() => {

        if (!currentSessionId) {
            if (localStorage.getItem('currentSessionId')) {
              setCurrentSessionId(localStorage.getItem('currentSessionId'));        
            } else {
                alert('empty currentSessionId'); setRedirect('/scan');
            }
        }

        if (!currentTokenPublisher) {
            if (localStorage.getItem('currentTokenPublisher')) {
              setCurrentTokenPublisher(localStorage.getItem('currentTokenPublisher'));        
            } else {
                alert('empty currentTokenPublisher'); setRedirect('/scan');
            }
        }

    }, []);

    const handleConnect = () => {
        setConnected(1);
    }
    const handleError = (error) => {
        setError(1);
        console.log(error);
    }

    const publisherProperties = {
        audioFallbackEnabled: false,
        showControls: false
    };
   
    const publisherEventHandlers = {
        streamCreated: event => {
          console.log('Publisher stream created!');
          setLog('Publisher stream created!' + '\n\r' + log);
        },
        streamDestroyed: event => {
          console.log('Publisher stream destroyed!');
          setLog('Publisher stream destroyed!' + '\n\r' + log);
        }
    };

    const subscriberProperties = {
        preferredFrameRate: 15,
        showControls: true
    };
    const subscriberEventHandlers = {
        videoDisabled: event => {
          console.log('Subscriber video disabled!');
          setLog('Subscriber video disabled!' + '\n\r' + log);
        },
        videoEnabled: event => {
          console.log('Subscriber video enabled!');
          setLog('Subscriber video enabled!' + '\n\r' + log);
        }
    };

    return(
        <Container>

            { (redirect) ? <Redirect to={ redirect } /> : null }

            { 
                (currentSessionId && currentTokenPublisher) 
                ?
                    <OTSession 
                        apiKey={ props.credentials.apiKey } 
                        sessionId={ currentSessionId } 
                        token={ currentTokenPublisher }
                        onConnect={ handleConnect }
                        onError={ handleError } >
                        <OTPublisher 
                            properties={ publisherProperties }
                            eventHandlers={ publisherEventHandlers }
                            className={ classes.videoMain } />
                        <OTStreams>
                            <OTSubscriber 
                                properties={ subscriberProperties }
                                eventHandlers={ subscriberEventHandlers }
                                className={ classes.videoSecundary } />
                        </OTStreams>
                    </OTSession>
                :
                    null
            }

            <Typography className={ classes.overVideoTitle }>{ (connected) ? 'CONECTADO' : 'DESCONECTADO' }</Typography>
            <Typography className={ classes.overVideoSubTitle }>{ (error) ? 'ERROR' : 'SIN ERROR' }</Typography>
            <Typography className={ classes.overVideoLog }>{ log }</Typography>

        </Container>
    );
}