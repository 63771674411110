import React, {useReducer} from 'react';

import currentContext from './currentContext';
import currentReducer from './currentReducer';

import {SET_CURRENT_TOKEN,
        SET_CURRENT_ROL,
        SET_CURRENT_PAGE,
        SET_CURRENT_SESSION_ID,
        SET_CURRENT_TOKEN_PUBLISHER,
        SET_CURRENT_TOKEN_SUBSCRIBER
} from '../../Types/index';

const CurrentState = props => {
    const initialState = {
        currentToken: null,
        currentRol: null,
        currentPage: null,
        currentSessionId: null,
        currentTokenPublisher: null,
        currentTokenSubscriber: null
    }

    const [state, dispatch] = useReducer(currentReducer, initialState);

    const setCurrentToken = token => {
        dispatch({
            type: SET_CURRENT_TOKEN,
            payload: token
        })
    }

    const setCurrentRol = rol => {
        dispatch({
            type: SET_CURRENT_ROL,
            payload: rol
        })
    }

    const setCurrentPage = page => {
        dispatch({
            type: SET_CURRENT_PAGE,
            payload: page
        })
    }

    const setCurrentSessionId = sessionId => {
        dispatch({
            type: SET_CURRENT_SESSION_ID,
            payload: sessionId
        })
    }

    const setCurrentTokenPublisher = tokenPublisher => {
        dispatch({
            type: SET_CURRENT_TOKEN_PUBLISHER,
            payload: tokenPublisher
        })
    }

    const setCurrentTokenSubscriber = tokenSubscriber => {
        dispatch({
            type: SET_CURRENT_TOKEN_SUBSCRIBER,
            payload: tokenSubscriber
        })
    }

    return(
        <currentContext.Provider
            value = {{
                currentToken: state.currentToken,
                currentRol: state.currentRol,
                currentPage: state.currentPage,
                currentSessionId: state.currentSessionId,
                currentTokenPublisher: state.currentTokenPublisher,
                currentTokenSubscriber: state.currentTokenSubscriber,
                
                setCurrentToken,
                setCurrentRol,
                setCurrentPage,
                setCurrentSessionId,
                setCurrentTokenPublisher,
                setCurrentTokenSubscriber
            }}
        >
            {props.children}
        </currentContext.Provider>
    )
}

export default CurrentState;