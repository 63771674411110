import { makeStyles } from '@material-ui/core/styles';

export const themeAppDefault = {
    palette: {
      primary: { // used to represent primary interface elements for a user.
        // light: will be calculated from palette.primary.main,
        main: '#ff4400',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: { // used to represent secondary interface elements for a user.
        light: '#0066ff',
        main: '#0044ff',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#ffcc00',
      },
      error: { // used to represent interface elements that the user should be made aware of.
        // light: will be calculated from palette.primary.main,
        main: '#f44336',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      warning: { // used to represent potentially dangerous actions or important messages.
        // light: will be calculated from palette.primary.main,
        main: '#ffc107',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      info: { // used to present information to the user that is neutral and not necessarily important.
        // light: will be calculated from palette.primary.main,
        main: '#2196f3',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      success: { // used to indicate the successful completion of an action that user triggered.
        // light: will be calculated from palette.primary.main,
        main: '#4caf50',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
        // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
      type: 'light',
    },
};

export const useStylesApp = makeStyles(theme => ({
    full: {
      width: '100vw !important',
      height: '100vh !important',
      margin: '0px !important',
      padding: '0px !important',
      overflow: 'hidden'
    },

    noMargin: {
      margin: '0px !important'
    },
    noPadding: {
      padding: '0px !important'
    },
  
    hidden: {
      display: 'none !important'
    },
    
    container: {
      background: '#F0F0F0',
      width: '100vw !important',
      height: '100vh !important',
      padding: 0
    },
    content: {
      padding: '15px'
    },

    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,.15)',
      color: '#333'
    },
    overVideoLogPublisher: {
      background: '#000E',
      color: '#FFF',
      fontSize: '12px !important',
      lineHeight: 1,
      position: 'absolute',
      top: 100,
      left: 10,
      width: '40vw',
      zIndex: 200
    },
    overVideoLogSubscriber: {
      background: '#000E',
      color: '#FFF',
      fontSize: '12px !important',
      lineHeight: 1,
      position: 'absolute',
      width: '40vw',
      top: 100,
      right: 10,
      zIndex: 200
    },
    containerChipState: {
      position: 'absolute',
      left: 0,
      bottom: 10,
      textAlign: 'center',
      width: '100vw',
      zIndex: 200     
    },
    overVideoError: {
      height: '60px',
      position: 'absolute',
      textAlign: 'center',
      top: 0,
      left: 0,
      marginTop: 'calc(50vh - 30px)'     
    },

    btnBack: {
      position: 'absolute !important;',
      top: theme.spacing(2),
      left: theme.spacing(2),
      minWidth: '32px !important',
      width: 32,
      height: 32,
      padding: 0
    },
    fab: {
      position: 'absolute !important;',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },

}));