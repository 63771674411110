import React, { useState, useContext, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Container, Typography, Button, IconButton, Grid, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";

import CallEndIcon from '@material-ui/icons/CallEnd';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';

import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';

import * as firebase from 'firebase';

import http from "../Utils/http";
import { makeStyles } from '@material-ui/core/styles';
import { useStylesApp } from "../appStyles";
import clsx from 'clsx';

import currentContext from "../Context/current/currentContext";

export default function VideoCallJoin(props) {
    const [redirect, setRedirect] = useState(null);
    const [connected, setConnected] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [logPublisher, setLogPublisher] = useState('');
    const [logSubscriber, setLogSubscriber] = useState('');
    const [guestName, setGuestName] = useState(null);
    const [showConfirmCallEnd, setShowConfirmCallEnd] = useState(false);

    const [videoCallKey, setVideoCallKey] = useState(null);
    const [connectionId, setConnectionId] = useState(null);
    const [connection, setConnection] = useState('Connecting');
    const [publishOk, setPublishOk] = useState(false);
    const [subscribeOk, setSubscribeOk] = useState(false);

    const [consultationDataMeducar, setConsultationDataMeducar] = useState(null);
    const [stateData, setStateData] = useState(null);

    const { currentRol, setCurrentRol, currentSessionId, setCurrentSessionId, currentTokenSubscriber, setCurrentTokenSubscriber } = useContext(currentContext);

    const useStylesPage = makeStyles(theme => ({
        contentConnecting: {
            color: '#666',
        },
        connectingProgress: {
            color: '#999',
            marginBottom: '20px'
        },
        connectingLabelSmall: {
          fontSize: '0.75rem',
        },
        connectingLabelMain: {
            fontSize: '1.25rem',
            fontWeight: 400
        },
        errorIcon: {
            fontSize: '8rem',
        },
        errorLabel: {
            fontSize: '1rem',
        },
    }));

    const location = useLocation();
    const classes = useStylesApp();
    const pageClass = useStylesPage();

    const apiKey = '46588282';
    const debug = false;

    useEffect(() => {

        let auxCurrentRol = currentRol;
        if (!auxCurrentRol) {
            auxCurrentRol = localStorage.getItem('currentRol');
            setCurrentRol(auxCurrentRol);            
        }

        // var arr = location.pathname.split('/');
        // if (arr.length == 3) {
        //     console.log(arr[2]);
        //     getDataVideoCall(arr[2]);
        //     getDataVideoCallMeducar(arr[2]);
        // }

        // if (!currentSessionId) { alert('empty currentSessionId'); setRedirect('/scan'); }
        // if (!currentTokenPublisher) { alert('empty currentTokenPublisher'); setRedirect('/scan'); }
      
    }, []);

    useEffect(() => {
        if (videoCallKey) {
            getDataVideoCallMeducar(videoCallKey);
        }
    }, [videoCallKey]);

    useEffect(() => {
        if (currentRol) {
            var arr = location.pathname.split('/');
            if (arr.length === 3) {
                console.log(arr[2]);
                setVideoCallKey(arr[2]);
            }
        }
    }, [currentRol]);

    useEffect(() => {
        if (consultationDataMeducar) {
            if (consultationDataMeducar.finished) {
                if (consultationDataMeducar.finished_msg) {
                    setMessage(consultationDataMeducar.finished_msg);
                } else {
                    setMessage('La conexión fue finalizada.');
                }
            } else {
                const stateRef = firebase.database().ref().child('videocalls').child(consultationDataMeducar.professional_id).child(consultationDataMeducar.event_id).child(consultationDataMeducar.patient_id);
                stateRef.on('value', snapshot => {   
                    setStateData(snapshot.val());
                });
            }
        }
    }, [consultationDataMeducar]);

    useEffect(() => {
        if (stateData) {

            getDataVideoCall(videoCallKey);

            console.log(stateData);

            let msg = '';
            switch (stateData.state) {
                case 13:
                    if (currentRol == 'patient') {
                        http.setPatientJoinVideo(consultationDataMeducar.event_id, consultationDataMeducar.patient_id);
                    }
                    break;
                case 14:
                    if (currentRol == 'professional') {
                        http.setProfessionalJoinVideo(consultationDataMeducar.event_id, consultationDataMeducar.professional_id);
                    }
                    break;

                case 20:
                    //BOTH -> nada
                    return;

                case 97:
                    msg = 'La conexión fue finalizada por el paciente.';
                    break;
                case 98:
                    msg = 'La conexión fue finalizada por el profesional.';
                    break;
                case 99:
                    msg = 'La conexión ya finalizada por el profesional.';
                    break;

                default:
                    msg = 'La conexión ya no está disponible.';
                    break;
            }
            if (msg != '') {
                setError(msg);
            }      
            
        }
    }, [stateData]);

    async function getDataVideoCall(videoCallKey) {
        let resp = await http.getDataVideoCall(videoCallKey);
        console.log(resp);
        if (resp.status == 200) {
    
          console.log(resp.data.data);
    
          if (resp.data.data) {
           
            setCurrentSessionId(resp.data.data.session_id);
            localStorage.setItem('currentSessionId', resp.data.data.session_id);

            setCurrentTokenSubscriber(resp.data.data.token);
            localStorage.setItem('currentTokenSubscriber', resp.data.data.token);
            // setRedirect('/video-call');

          } else {

            setError('La videollamada ya no está disponible.');

          }
    
        } else {
            setError('La videollamada ya no está disponible.');
        }
    }

    async function getDataVideoCallMeducar(videoCallKey) {
        let resp = await http.getDataVideoCallMeducar(videoCallKey);
        console.log(resp);
        if (resp.status == 200) {
    
          if (resp.data.data.data) {

            const connectionData = resp.data.data;
            connectionData.data = JSON.parse(connectionData.data);            

            if (currentRol) {
                if (currentRol == 'professional') {
                    setGuestName(connectionData.data.Patient.name + ' ' + connectionData.data.Patient.surname);
                } else {
                    setGuestName(connectionData.data.UserProfessional.name + ' ' + connectionData.data.UserProfessional.surname);
                }
            } 
            
            setConsultationDataMeducar(connectionData);
           
          } else {
            setError('La información de la videollamada ya no está disponible.');
          }
    
        } else {
            if (resp.data.message) {
                setError(resp.data.message);
            } else {
                setError('La información de la videollamada no está disponible.');
            }
        }
    }

    const handleConnect = (data) => {
        console.log(data);
        setConnected(1);
    }
    const handleError = (error) => {
        console.log(error);
        if (error.name) {
            switch(error.name) {
                case 'OT_AUTHENTICATION_ERROR':
                    http.setTimeout(videoCallKey);
                    setError('La conexión ya no está disponible.');
                    break;

                case 'OT_CONNECT_FAILED':
                    http.setTimeout(videoCallKey);
                    setError('La conexión ya no está disponible.');
                    break;

                default:
                    setError('Error: ' + error.name);
            }
        } else {
            setError('Error desconocido.');
        }
        console.log(error);
    }

    const publisherProperties = {
        audioFallbackEnabled: false,
        showControls: true,
    };
   
    const publisherEventHandlers = {
        streamCreated: event => {
            console.log(event);
            console.log('Publisher stream created!');

            let aux = 'Publisher stream created!' + "\r\n";
            aux += 'StreamId' + "\r\n";
            aux += event.stream.streamId + "\r\n";
            aux += 'ConnectionId' + "\r\n";
            aux += event.stream.connection.connectionId + "\r\n";
            aux += 'hasAudio: ' + event.stream.hasAudio + "\r\n";
            aux += 'hasVideo: ' + event.stream.hasVideo + "\r\n";
            aux += 'videoType: ' + event.stream.videoType + "\r\n";

            setLogPublisher(aux + logPublisher);

            if (currentRol == 'professional') {
                http.setConnectionId(videoCallKey, event.stream.connection.connectionId, null);
            } else {
                http.setConnectionId(videoCallKey, null, event.stream.connection.connectionId);
            }           
            setConnectionId(event.stream.connection.connectionId); 
        },
        streamDestroyed: event => {
            console.log(event);
            console.log('Publisher stream destroyed!');
            setLogPublisher('Publisher stream destroyed!' + "\r\n" + logPublisher);

            if (event.reason == 'forceDisconnected') {
                setMessage('La conexión ha sido finalizada.');
                setCurrentSessionId(null);
                setCurrentTokenSubscriber(null);
            }
        }
    };

    const subscriberProperties = {
        preferredFrameRate: 30,
        showControls: true
    };
    const subscriberEventHandlers = {
        videoEnabled: event => {
            console.log(event);
            console.log('Subscriber video enabled!');
            
            let aux = 'Subscriber video enabled!' + "\r\n";
            aux += 'StreamId' + "\r\n";
            aux += event.stream.streamId + "\r\n";
            aux += 'ConnectionId' + "\r\n";
            aux += event.stream.connection.connectionId + "\r\n";
            aux += 'hasAudio: ' + event.stream.hasAudio + "\r\n";
            aux += 'hasVideo: ' + event.stream.hasVideo + "\r\n";
            aux += 'videoType: ' + event.stream.videoType + "\r\n";

            setLogSubscriber(aux + "\r\n" + logSubscriber);
        },
        videoDisabled: event => {
            console.log(event);
            console.log('Subscriber video disabled!');            
        }        
    };

    const sessionEventHandlers = {
        sessionConnected: () => {
          setConnection('Conectado');
        },
        sessionDisconnected: () => {
          setConnection('Desconectado');
        },
        sessionReconnected: () => {
          setConnection('Reconectado');
        },
        sessionReconnecting: () => {
          setConnection('Reconectando');
        }
    };

    const onPublish = () => {
        setPublishOk(true);
        console.log('onPublish Success');
    };
    const onPublishError = (error) => {
        setPublishOk(false);
        console.log('onPublish Error', error);
    };

    const onSubscribe = () => {
        setSubscribeOk(true);
        console.log('onSubscribe Success');
    };
    const onSubscribeError = (error) => {
        setSubscribeOk(false);
        console.log('onSubscribe Error', error);
    };

    const onClickDisconnect = (showConfirm) => {
        if (showConfirm) {
            setShowConfirmCallEnd(true);
        } else {

            // TODO_G: CONTROL DE ERRORES
            http.disconnectConnectionId(currentSessionId, connectionId);
            http.disconnectVideoKey(videoCallKey);

            setCurrentSessionId(null);
            setCurrentTokenSubscriber(null);

            if (currentRol == 'professional') {
                setRedirect('/');
            } else {
                setRedirect('/out');
            }
            
        }        
    }
    const onClickConfirmCallEndClose = (result) => {
        if (result) {
            onClickDisconnect(0);
        } 
        setShowConfirmCallEnd(false);
    }

    return(
        <>
            { (redirect) ? <Redirect to={ redirect } /> : null }
            
            <Container>               
                
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={ classes.overlay } >

                    { 
                        (error != '')
                        ?
                            <Grid item xs={12} align="center" className={ clsx(classes.content, pageClass.contentConnecting) } >
                                <VideocamOffIcon className={ pageClass.errorIcon } />
                                <Typography align="center" className={ pageClass.errorLabel }>{ error }</Typography>    
                                <Button onClick={ () => onClickDisconnect(0) }>Salir</Button>
                            </Grid>
                        :
                            (message != '')
                            ?
                                <Grid item xs={12} align="center" className={ clsx(classes.content, pageClass.contentConnecting) } >
                                    <VideocamOffIcon className={ pageClass.errorIcon } />
                                    <Typography align="center" className={ pageClass.errorLabel }>{ message }</Typography>    
                                    <Button onClick={ () => onClickDisconnect(0) }>Salir</Button>
                                </Grid>
                            :
                                (guestName)
                                ?
                                    <Grid item xs={12} align="center" className={ clsx(classes.content, pageClass.contentConnecting) } >
                                        <CircularProgress className={ pageClass.connectingProgress } />
                                        <Typography align="center" className={ pageClass.connectingLabelSmall }>Esperando la conexión de</Typography>
                                        <Typography align="center" className={ pageClass.connectingLabelMain }>{ guestName }</Typography>
                                    </Grid>
                                :
                                    null
                        
                    }

                </Grid>                        
                
                { 
                    (currentSessionId && currentTokenSubscriber) 
                    ?
                        <>
                            <OTSession 
                                apiKey={ apiKey } 
                                sessionId={ currentSessionId } 
                                token={ currentTokenSubscriber }
                                onConnect={ handleConnect }
                                onError={ handleError }
                                eventHandlers={ sessionEventHandlers } >
                                <OTPublisher 
                                    properties={ publisherProperties }
                                    onPublish={ onPublish }
                                    onError={ onPublishError }
                                    eventHandlers={ publisherEventHandlers }
                                    className={ classes.videoMain } />
                                <OTStreams>
                                    <OTSubscriber 
                                        properties={ subscriberProperties }
                                        onSubscribe={ onSubscribe }
                                        onError={ onSubscribeError }
                                        eventHandlers={ subscriberEventHandlers }
                                        className={ classes.videoSecundary } />
                                </OTStreams>
                            </OTSession>
                            <Container className={ classes.containerChipState } >
                                {
                                    (publishOk && subscribeOk && !error) 
                                    ?
                                        <>
                                            <IconButton component="span" onClick={ () => onClickDisconnect(1) }>
                                                <CallEndIcon color="secondary" fontSize="large" />
                                            </IconButton>
                                        </>
                                    :
                                        null
                                }
                            </Container>
                        </>
                    :
                        null
                }

                {/* <Container className={ classes.containerChipState } >
                    {
                        (publishOk && subscribeOk) 
                        ?
                            <>
                                <IconButton component="span" onClick={ () => onClickDisconnect(1) }>
                                    <CallEndIcon color="secondary" fontSize="large" />
                                </IconButton>
                            </>
                        :
                            null
                    }
                </Container> */}
            
                
                { 
                    (debug) 
                    ?
                        <>
                            <Typography className={ classes.overVideoLogPublisher }>{ logPublisher }</Typography>
                            <Typography className={ classes.overVideoLogSubscriber }>{ logSubscriber }</Typography>
                            <div id="sessionStatus" className={ classes.overVideoLogSubscriber }>Session Status: { connection }</div>
                        </>
                    :
                        null
                }         
                

            </Container>


            <Dialog
                open={ showConfirmCallEnd }
                onClose={ () => onClickConfirmCallEndClose(0) }
                // PaperComponent={PaperComponent}
                aria-labelledby="call-end-dialog-title" >
                <DialogTitle style={{ cursor: 'move' }} id="call-end-dialog-title">
                    Finalizar
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Está seguro que desea finalizar la videollamada?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={ () => onClickConfirmCallEndClose(0) } color="primary">
                        No
                    </Button>
                    <Button onClick={ () => onClickConfirmCallEndClose(1) } color="secondary">
                        Si, finalizar
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}