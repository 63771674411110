import React, { useState, useContext, useEffect } from "react";

import { Typography, Container } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import http from "../Utils/http";

import currentContext from "../Context/current/currentContext";

export default function ConfirmInit(props) {
  const [redirect, setRedirect] = useState(null);
  const { currentSessionId, setCurrentSessionId, currentTokenPublisher, setCurrentTokenPublisher } = useContext(currentContext);

  useEffect(() => {

    if (!currentSessionId) {
      if (localStorage.getItem('currentSessionId')) {
        setCurrentSessionId(localStorage.getItem('currentSessionId'));        
      } else {
        setRedirect('/scan');
      }
    }

    getTokenPublisher();
  
  }, []);
  

  // const location = useLocation();
  // let data = '';
  // if (location.state.url) {
  //   data = location.state.url;
  // } else {
  //   //TODO_G: DELETE
  //   data = {
  //     url: 'http://localhost:8000',
  //     consultation_id: 1
  //   }
  // }

  const handleClick = () => {
    getTokenPublisher();
  }

  // async function createSession() {
  //   let resp = await http.createSession();
  //   console.log(resp);
  //   if (resp.status == 200) {

  //     if (resp.data.id) {
  //       setCurrentSessionId = resp.data.id;
  //       setRedirect(true);
  //     }

  //   } else {
  //     alert('error');
  //   }    
  // }
  
  async function getTokenPublisher() {
    let resp = await http.getTokenPublisher(currentSessionId);
    console.log(resp);
    if (resp.status == 200) {

      if (resp.data.data.token) {
        
        setCurrentTokenPublisher(resp.data.data.token);
        localStorage.setItem('currentTokenPublisher', resp.data.data.token);

        setRedirect('/video-call');
      }

    } else {
      alert('error');
    }    
  }

  return (
    <Container>
      
      { (redirect) ? <Redirect to={ redirect } /> : null }
      
      <Typography align="center" variant="h6">Confirm Init</Typography>
      <Typography>SessionId { currentSessionId }</Typography>
      <Typography>TokenPublisher { currentTokenPublisher }</Typography>

      {/* <Button onClick={ handleClick }>Iniciar</Button> */}
    </Container>
  );

}  
